<template>
  <div class="min-h-screen">
    <CookiesModal />

    <slot />
  </div>
</template>

<script lang="ts" setup>
import { useHead } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import CookiesModal from '@backmarket/nuxt-module-tracking/CookiesModal.vue'

const i18n = useI18n()

useHead({
  meta: [
    { charset: 'utf-8' },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, maximum-scale=1',
    },
  ],
  htmlAttrs: {
    lang: i18n.currentLocale,
  },
})
</script>

<style>
:root {
  font: -apple-system-body;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
</style>
